import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.titleNav || `Title`} />
      <div>
        <main>{children}</main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <footer>
                © {new Date().getFullYear()} &middot; Hecho con
                {` `}
                <svg width="20" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.2282 2.51574L22.8789 2.87348C21.8139 1.83354 20.3202 1.28577 18.3313 1.28577C17.8134 1.28577 17.2782 1.37545 16.7246 1.55995C16.1648 1.74655 15.646 1.99756 15.1675 2.31252L15.1672 2.31272C14.6606 2.64564 14.23 2.95517 13.8726 3.24082C13.515 3.52705 13.1752 3.83108 12.8535 4.15268L12.5001 4.50608L12.1466 4.15276C11.8247 3.83112 11.4851 3.52713 11.1275 3.24099C10.7703 2.95541 10.3395 2.64599 9.83259 2.31263L9.83253 2.31259C9.35356 1.99748 8.83488 1.74664 8.27519 1.55997L23.2282 2.51574ZM23.2282 2.51574L22.8789 2.87349C23.9416 3.91113 24.5 5.369 24.5 7.31469C24.5 9.18562 23.5323 11.1575 21.455 13.2356L12.767 21.6031L12.7669 21.603L12.7604 21.6095C12.689 21.6809 12.6123 21.7142 12.5 21.7142C12.3878 21.7142 12.3113 21.681 12.2398 21.6094L12.2398 21.6094L12.2333 21.603L3.52805 13.2045L3.51121 13.1883L3.49293 13.1737C3.42533 13.1197 3.31523 13.0171 3.15598 12.8535C3.01165 12.7047 2.76646 12.4171 2.41341 11.9758L2.41339 11.9758C2.07336 11.5507 1.76998 11.1155 1.50307 10.6707L1.50307 10.6706C1.24747 10.2447 1.01255 9.71734 0.80262 9.08279L0.802603 9.08274C0.598953 8.46742 0.5 7.8786 0.5 7.31471C0.5 5.36899 1.05832 3.91112 2.12106 2.87349L2.12107 2.87348M23.2282 2.51574L2.12107 2.87348M2.12107 2.87348C3.18594 1.83368 4.6797 1.28577 6.66845 1.28577C7.18631 1.28577 7.72129 1.37547 8.27503 1.55991L2.12107 2.87348Z" fill="#FDAD34" stroke="#FDAD34" />
                </svg>

                por TRAINFES devTeam.
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
