import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import axios from 'axios'
import fileDownload from 'js-file-download'
import LoaderContainer from "../components/loading"

const server = "https://apiqr.trainfes-cloud.com";

const IndexPage = () => {

  const [downloadEnabled, setDownloadEnabled] = useState(false);
  const [text, setText] = useState(null);
  const [previewEnable, setPreviewEnable] = useState(false);
  const [type, setType] = useState("modern-color");
  const [ext, setExt] = useState("png");
  const [urlResponse, setUrlResponse] = useState(null);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [msg, setMsg] = useState("png");

  const gerenar = () => {

    if (text == null || text == undefined || text.length == 0 || text === '') {
      setError(true);
      return false;
    }

    setMsg("Estamos creando tu código QR, un momento por favor");
    setLoader(true);
    setError(false);
    setDownloadEnabled(false);
    axios.post(`${server}/generate`, { text: text, type: type, ext: ext })
      .then(response => {
        setUrlResponse(response.data.imageUrl);
        setPreviewEnable(true);
        setMsg("Sólo unos segundos más...");
        setTimeout(() => {
          setDownloadEnabled(true);
          setLoader(false);
        }, 2000);
      })
      .catch(err => console.log(err))
  }

  const download = () => {
    axios.post(`${server}/download`, { url: 'https:' + urlResponse })
      .then(response => {
        axios.get(`${server}${response.data.url}`, { responseType: "blob" })
          .then(res => fileDownload(res.data, response.data.name))
          .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
  }

  return (
    <Layout>
      <Seo title="Home" />
      <section>
        <div className="container controls">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="row">
                <div className="col-12">
                  <div className="sub-title-p pb-3">
                    <p>Ingrese URL o texto del QR</p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="input-container">
                    <input type="text" id="text" className="borde-input" required onChange={v => setText(v.target.value)} value={text} />
                    <label for="#text" className="borde-label">URL o Texto<span className="red"> *</span></label>
                  </div>
                  {error ? <div className="error-alert">*El campo url o texto es obligatorio</div> : <div className="error-alert"></div>}
                </div>
                <div className="col-12">
                  <div className="divider"></div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="sub-title-p pb-3">
                        <p>Diseño de QR</p>
                      </div>
                    </div>
                    <div className="col-4">
                      <label className="radio-container" htmlFor="modern-color">
                        <input type="radio" name="radio" checked={type === "modern-color"} id="modern-color" onClick={v => setType("modern-color")} />
                        <span>Modern Color</span>
                      </label>
                    </div>
                    <div className="col-4">
                      <label className="radio-container" htmlFor="color">
                        <input type="radio" name="radio" checked={type === "color"} id="color" onClick={v => setType("color")} />
                        <span>Modern</span>
                      </label>
                    </div>
                    <div className="col-4">
                      <label className="radio-container" htmlFor="classic">
                        <input type="radio" name="radio" checked={type === "classic"} id="classic" onClick={v => setType("classic")} />
                        <span>Classic</span>
                      </label>
                    </div>
                    <div className="col-12">
                      <div className="divider"></div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="sub-title-p pb-3">
                        <p>Tipo de extención</p>
                      </div>
                    </div>
                    <div className="col-4">
                      <label className="radio-container" htmlFor="png">
                        <input type="radio" name="ext" id="png" checked={ext === "png"} onClick={v => setExt("png")} />
                        <span>PNG</span>
                      </label>
                    </div>
                    <div className="col-4">
                      <label className="radio-container" htmlFor="svg">
                        <input type="radio" name="ext" id="svg" checked={ext === "svg"} onClick={v => setExt("svg")} />
                        <span>SVG</span>
                      </label>
                    </div>
                    <div className="col-12">
                      <div className="divider mb-5"></div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <button className="btn-primary" onClick={gerenar}>Generar</button>
                </div>
                <div className="col-6">
                  <button disabled={!downloadEnabled} onClick={download} className="btn-secondary">Descargar</button>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-5"></div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="sub-title-p pb-3 mb-2">
                <p>PREVIEW</p>
              </div>
              <div className="box-qr">
                <LoaderContainer maxHeight={330} text={msg} active={loader}>
                  {!previewEnable ? <p>QR aún no generado</p> : <><img src={urlResponse} /></>}
                </LoaderContainer>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const Head = () => <Seo title="QR Generator" />

export default IndexPage
