import * as React from "react"
import PropTypes from "prop-types"

const Header = (props) => (
  <header>
    <div className="container">
      <div className="row">
        <div className="col-6">
          <div className="flex-container start" style={{color:"#281B65"}}>
            TRAINFES QR GENERATOR
          </div>
        </div>
        <div className="col-6">
          <div className="flex-container end">
            <svg width="90" height="40" viewBox="0 0 245 76" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M29.4217 18.1186C29.4695 18.1738 29.4783 18.2944 29.4506 18.4792L28.2751 26.4095C28.2729 26.4725 28.2578 26.5344 28.2307 26.5913C28.2037 26.6483 28.1653 26.6991 28.1179 26.7406C28.0705 26.7822 28.0151 26.8135 27.955 26.8328C27.895 26.8522 27.8317 26.859 27.769 26.8529H18.6607L14.2873 56.3535C14.2658 56.4747 14.2006 56.5837 14.1039 56.6599C14.0171 56.7442 13.9021 56.7935 13.7812 56.7981H5.44551C5.38607 56.804 5.32612 56.7944 5.27153 56.7701C5.21693 56.7459 5.16956 56.7079 5.13403 56.6599C5.07504 56.5694 5.05175 56.4602 5.06867 56.3535L9.43315 26.9078H0.37987C0.325359 26.9078 0.271578 26.8953 0.222608 26.8713C0.173638 26.8474 0.130766 26.8126 0.0972438 26.7696C0.05979 26.7288 0.0319959 26.68 0.0159142 26.627C-0.000167498 26.5739 -0.00412534 26.518 0.00433375 26.4632L1.18865 18.4792C1.19469 18.4137 1.21451 18.3501 1.24684 18.2928C1.27916 18.2354 1.32325 18.1855 1.37621 18.1464C1.46778 18.076 1.57971 18.0372 1.69519 18.0359H29.0771C29.2604 18.0359 29.376 18.0634 29.4225 18.1188" fill="#281B65" />
              <path d="M57.0132 56.7427C56.9593 56.8532 56.8223 56.9085 56.6013 56.9085H46.9966C46.9213 56.9175 46.8451 56.9005 46.7807 56.8603C46.7163 56.8201 46.6675 56.7591 46.6424 56.6875L41.7372 42.4899L39.4816 42.4347L37.4344 56.2428C37.4222 56.341 37.3897 56.4355 37.3389 56.5204C37.3013 56.5819 37.2557 56.6381 37.2032 56.6875C37.0765 56.8156 36.9081 56.894 36.7284 56.9085H28.4486C28.3939 56.9085 28.3399 56.896 28.2907 56.8721C28.2415 56.8482 28.1984 56.8133 28.1647 56.7703C28.1278 56.7291 28.1004 56.6803 28.0846 56.6273C28.0687 56.5743 28.0648 56.5185 28.0731 56.4638L33.7024 18.4774C33.7091 18.4121 33.7292 18.349 33.7615 18.2919C33.7938 18.2348 33.8375 18.1849 33.8899 18.1455C33.9821 18.0753 34.0943 18.0366 34.2102 18.035H49.9421C51.8663 17.961 53.5752 18.3491 55.069 19.1993C56.559 20.0459 57.7804 21.2952 58.5933 22.8039C59.4474 24.3562 59.8769 26.1121 59.8819 28.0714C59.8454 28.8112 59.7998 29.3659 59.745 29.7355C59.3832 32.2464 58.3853 34.6231 56.8462 36.6396C55.3442 38.6519 53.2739 40.1681 50.9022 40.9928L57.0866 56.2422C57.1162 56.2792 57.1378 56.3219 57.1503 56.3675C57.1627 56.4132 57.1656 56.461 57.1588 56.5078C57.1521 56.5547 57.1358 56.5996 57.111 56.6399C57.0861 56.6802 57.0533 56.715 57.0145 56.7421L57.0132 56.7427ZM50.8226 30.3448L50.8389 30.2343C50.9654 29.8032 50.9906 29.3487 50.9126 28.9062C50.8346 28.4637 50.6555 28.0453 50.3892 27.6834C50.1203 27.3104 49.7639 27.0092 49.3514 26.8062C48.9388 26.6032 48.4827 26.5046 48.0231 26.5192H41.8398L40.7057 34.1719H46.8889C47.3964 34.1728 47.8988 34.0701 48.3653 33.8699C48.8317 33.6696 49.2522 33.3762 49.6012 33.0076C50.2389 32.2451 50.6609 31.3257 50.8233 30.3449" fill="#281B65" />
              <path d="M99.4057 56.5759C99.3329 56.6877 99.2789 56.7417 99.2437 56.7417C99.1381 56.793 99.0199 56.8126 98.9034 56.7983H90.1818C90.1214 56.7952 90.0624 56.7788 90.0092 56.7501C89.956 56.7214 89.9098 56.6812 89.8741 56.6325L89.7886 56.4654L87.7289 50.2547H73.7625L69.8614 56.4654C69.8552 56.5031 69.8099 56.5583 69.7258 56.6325C69.6307 56.7281 69.5047 56.7868 69.3703 56.7983H60.6477C60.5741 56.8057 60.4998 56.7943 60.4317 56.7654C60.3636 56.7364 60.3039 56.6907 60.2582 56.6325C60.1829 56.5207 60.2168 56.3549 60.3599 56.1326L85.1228 17.9233C85.1679 17.8356 85.2374 17.7628 85.323 17.7137C85.4086 17.6647 85.5066 17.6415 85.6051 17.647C85.6656 17.6495 85.7247 17.6657 85.7781 17.6945C85.8314 17.7232 85.8775 17.7636 85.9128 17.8128L85.9982 17.9786L99.3886 56.1326C99.4291 56.1988 99.452 56.2744 99.4551 56.352C99.4582 56.4296 99.4413 56.5067 99.4062 56.5759H99.4057ZM83.0655 34.7822L79.2687 40.6601H85.0651L83.0655 34.7822Z" fill="#281B65" />
              <path d="M102.62 56.8533C102.548 56.7581 102.516 56.6387 102.532 56.5205L108.123 18.4219C108.14 18.2828 108.208 18.155 108.314 18.0629C108.401 17.9778 108.516 17.9281 108.638 17.9236H117.14C117.194 17.9239 117.248 17.9366 117.297 17.9608C117.346 17.9849 117.389 18.0198 117.422 18.0629C117.462 18.113 117.49 18.1711 117.505 18.2332C117.519 18.2952 117.52 18.3597 117.508 18.4222L111.916 56.5205C111.891 56.6487 111.825 56.7653 111.728 56.8533C111.687 56.9017 111.637 56.9414 111.581 56.9699C111.525 56.9984 111.463 57.0151 111.4 57.0191H102.898C102.842 57.0174 102.786 57.0014 102.737 56.9724C102.689 56.9434 102.648 56.9024 102.62 56.8533Z" fill="#281B65" />
              <path d="M150.242 0.443864L144.706 37.8003L125.21 18.5022C125.16 18.4342 125.089 18.3839 125.008 18.3589C124.928 18.3338 124.841 18.3352 124.761 18.3629C124.587 18.4194 124.481 18.5764 124.442 18.8351L117.269 68.397C117.259 68.457 117.263 68.5186 117.281 68.5768C117.299 68.6349 117.331 68.6878 117.374 68.7311L124.567 75.8512C124.818 76.0999 125.293 75.8926 125.347 75.5133L130.732 37.8568L150.284 57.1549C150.316 57.192 150.356 57.2213 150.401 57.2407C150.446 57.2601 150.495 57.2689 150.544 57.2667C150.606 57.2726 150.67 57.2657 150.73 57.2464C150.79 57.2271 150.845 57.1957 150.893 57.1542C150.94 57.1127 150.979 57.062 151.006 57.0051C151.033 56.9482 151.048 56.8863 151.051 56.8233L158.35 7.57402C158.361 7.51366 158.357 7.45161 158.339 7.39305C158.321 7.33449 158.289 7.28113 158.246 7.23743L151.022 0.10728C150.771 -0.138846 150.299 0.0658745 150.243 0.443864" fill="#FDAD34" />
              <path d="M189.124 8.22862C189.157 8.27654 189.18 8.33101 189.191 8.3884C189.201 8.44579 189.2 8.50478 189.186 8.56145L187.986 16.6575C187.979 16.7228 187.959 16.786 187.927 16.8433C187.895 16.9005 187.851 16.9505 187.799 16.9903C187.707 17.0611 187.595 17.1003 187.479 17.1021H173.125L172.246 23.0353L183.516 22.98C183.571 22.9802 183.625 22.9927 183.674 23.0167C183.722 23.0406 183.765 23.0753 183.799 23.1182C183.836 23.1593 183.864 23.208 183.88 23.261C183.896 23.314 183.9 23.3699 183.892 23.4247L182.682 31.5746C182.661 31.6954 182.596 31.8043 182.5 31.8811C182.413 31.9652 182.297 32.0144 182.176 32.0193L170.906 32.0745L168.779 46.4379C168.772 46.5033 168.751 46.5665 168.719 46.6238C168.687 46.681 168.643 46.731 168.59 46.7707C168.499 46.841 168.387 46.8802 168.271 46.8825H159.769C159.663 46.8839 159.56 46.8438 159.482 46.7707C159.44 46.7275 159.409 46.6742 159.394 46.6159C159.378 46.5576 159.378 46.4963 159.393 46.4379L165.007 8.56174C165.013 8.49632 165.033 8.43297 165.066 8.37568C165.098 8.3184 165.142 8.26842 165.194 8.22891C165.286 8.15853 165.399 8.11976 165.515 8.11839H188.81C188.867 8.11268 188.925 8.11962 188.979 8.13872C189.033 8.15782 189.083 8.18861 189.124 8.22891" fill="#281B65" />
              <path d="M201.89 23.035L212.276 22.9798C212.339 22.976 212.402 22.9865 212.461 23.0104C212.519 23.0343 212.572 23.0711 212.614 23.118C212.651 23.1587 212.679 23.2073 212.695 23.2601C212.711 23.3128 212.715 23.3686 212.707 23.4231L211.499 31.5756C211.469 31.7081 211.393 31.8258 211.285 31.9084C211.204 31.9806 211.1 32.02 210.991 32.0189L200.55 32.0755L199.679 37.9534H212.1C212.431 37.9534 212.575 38.1015 212.531 38.3967L211.33 46.4927C211.287 46.7904 211.1 46.9374 210.768 46.9374H189.46C189.353 46.9394 189.25 46.8992 189.173 46.8256C189.13 46.7824 189.1 46.729 189.085 46.6707C189.069 46.6124 189.069 46.5511 189.085 46.4927L194.706 8.56151C194.728 8.44104 194.793 8.33277 194.89 8.25753C194.976 8.17219 195.092 8.12246 195.214 8.11817H216.521C216.585 8.11443 216.648 8.12501 216.707 8.14914C216.765 8.17327 216.818 8.21031 216.86 8.25753C216.897 8.29831 216.925 8.34667 216.941 8.39919C216.957 8.45172 216.961 8.50713 216.953 8.56151L215.744 16.714C215.721 16.8341 215.657 16.9424 215.562 17.0191C215.457 17.1108 215.322 17.1601 215.182 17.1573H202.761L201.89 23.035Z" fill="#281B65" />
              <path d="M240.006 41.4741C238.704 43.3053 236.936 44.812 234.699 45.9944C232.463 47.1767 229.854 47.7682 226.873 47.7689C223.156 47.7689 219.817 46.9555 216.857 45.3286C216.767 45.2886 216.689 45.2274 216.628 45.1501C216.567 45.0728 216.526 44.9819 216.509 44.8852C216.474 44.7562 216.485 44.6191 216.539 44.4971L220.284 38.2299C220.369 38.1289 220.442 38.0173 220.499 37.898C220.628 37.6888 220.827 37.5315 221.06 37.4534C221.245 37.4534 221.666 37.584 222.329 37.8415C223.45 38.212 224.455 38.508 225.344 38.7295C226.322 38.9621 227.324 39.0739 228.329 39.0623C229.493 39.0708 230.65 38.8832 231.752 38.5071C232.818 38.1379 233.426 37.4543 233.574 36.4562C233.605 36.2914 233.611 36.1229 233.593 35.9563C233.587 35.2554 233.198 34.5902 232.426 33.9606C231.655 33.331 230.407 32.4987 228.684 31.4637C227.015 30.5159 225.41 29.4605 223.879 28.3042C222.632 27.3317 221.574 26.1379 220.758 24.7824C219.916 23.3957 219.591 21.7969 219.785 19.9858C220.246 15.8813 221.908 12.8035 224.77 10.7524C227.632 8.7014 231.05 7.67568 235.024 7.67529C237.983 7.67781 240.916 8.24254 243.665 9.33946C243.745 9.35188 243.821 9.3835 243.886 9.43161C243.951 9.47972 244.004 9.5429 244.039 9.61574C244.073 9.68452 244.092 9.75969 244.095 9.83632C244.097 9.91294 244.084 9.9893 244.055 10.0604L240.836 16.8815C240.629 17.2872 240.482 17.5283 240.398 17.6011C240.314 17.674 240.144 17.694 239.893 17.6564C238.808 17.1456 237.675 16.7462 236.509 16.4644C235.333 16.1611 234.123 16.003 232.908 15.9934C231.766 15.9934 230.815 16.1964 230.057 16.6025C229.692 16.7907 229.379 17.0664 229.146 17.405C228.912 17.7435 228.767 18.1344 228.721 18.5429C228.677 18.7997 228.657 19.06 228.661 19.3204C228.698 20.0601 229.097 20.7254 229.856 21.3161C230.614 21.909 231.854 22.7224 233.579 23.7564C235.288 24.6779 236.933 25.7156 238.501 26.8625C239.793 27.8453 240.859 29.0932 241.629 30.5223C242.439 32.0019 242.697 33.7397 242.402 35.7358C242.107 37.732 241.308 39.6447 240.006 41.4741Z" fill="#281B65" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
